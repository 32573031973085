input.search-input-field.disabled {
  background-color: var(--disabled-bg) !important;
  color: var(--disabled-text) !important;
}
input.search-input-field.disabled::placeholder {
  color: var(--disabled-text) !important;
}
input.search-input-field::placeholder {
  font-size: 15px;
}

.disabled-button {
  background-color: var(--disabled-bg) !important;
  color: var(--disabled-text) !important;
  border: none !important;
  cursor: not-allowed !important;
}

.parent-wrapper {
  width: 100%;
  font-family: var(--font-family-primary);
}

.loader-wrapper {
  display: flex;
  width: 100%;
  height: 200px;
  align-items: center;
  justify-content: center;
}

.parent-wrapper .header-wrapper {
  background-color: var(--primary-blue);
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 24px 120px;
}

.title {
  height: 48px;
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 48px;
  color: var(--white);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.parent-wrapper .header-wrapper {
  color: var(--white);
  font-size: 35px;
  font-weight: 600;
  line-height: 47px;
}

.parent-wrapper .header-wrapper .search-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.parent-wrapper .header-wrapper .search-wrapper input {
  background-color: var(--white);
  border-radius: 8px;
  border: none;
  font-size: 16px;
  margin-top: 7px;
  min-width: 380px;
  padding: 8px 12px;
}

.parent-wrapper .header-wrapper .search-wrapper button {
  border: 2px solid var(--primary-blue);
  background-color: var(--light-white);
  border-radius: 8px;
  color: var(--primary-blue);
  margin-top: 7px;
  margin-left: 10px;
  font-size: 16px;
  padding: 8px 20px;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
}

.parent-wrapper .header-wrapper .search-wrapper button:hover {
  border: 2px solid var(--white);
  background-color: var(--light-blue-100);
  border-radius: 8px;
  color: var(--primary-blue);
  margin-top: 7px;
  margin-left: 10px;
  font-size: 16px;
  padding: 8px 20px;
  cursor: pointer;
}

.parent-wrapper .header-wrapper .search-wrapper .icon-placeholder {
  display: flex;
  position: relative;
}

.parent-wrapper .header-wrapper .search-wrapper .icon-placeholder .icon {
  position: absolute;
  right: 8px;
  margin-top: -3.5px;
}

.parent-wrapper .content-wrapper {
  background-color: var(--white);
  padding: 24px 120px;
}

.page-title {
  height: 35px;
  left: 100px;
  top: 240px;
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: var(--black) !important;
}

.parent-wrapper .content-wrapper {
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  color: var(--black);
  min-height: 62vh;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1020px) {
  .parent-wrapper .content-wrapper {
    padding: 24px 30px;
  }
  .parent-wrapper .header-wrapper {
    padding: 24px 15px;
  }
  .parent-wrapper .header-wrapper, .parent-wrapper .header-wrapper .search-wrapper {
    display: flex;
    flex-direction: row !important;
  }
}

.no-devices-found {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark-grey-100);
}

@media screen and (min-width: 2880px) {
  .content-wrapper {
    min-height: 74vh !important;
  }
}


@media screen and (min-width: 3840px) {
  .content-wrapper {
    min-height: 81vh !important;
  }
}

.parent-wrapper .content-wrapper .table-wrapper {
  display: flex;
  width: 100%;
  margin: 30px auto 40px;
}

.device-model .table-row-text {
  height: 24px;
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.device-model {
  color: var(--text-blue) !important;
  cursor: pointer;
}

.table-row-text {
  color: var(--text-dark-grey) !important;
}

.parent-wrapper .content-wrapper .table-wrapper {
  color: var(--text-blue);
}

.pagination-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.pagination-wrapper-view {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
}

.pagination-wrapper-view-text {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--light-grey-600) !important;
  text-align: center;
  margin-top: 3px;
}

.pagination-result__wrapper-view {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
}

.pagination_result_text {
  height: 28px;
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  color: var(--light-grey-600);
  flex: none;
  order: 0;
  flex-grow: 0;
}

@media screen and (max-width: 992px) {

  .parent-wrapper .header-wrapper,
  .parent-wrapper .header-wrapper .search-wrapper {
    display: flex;
  }
}
